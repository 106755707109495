<template>
	<div class="returnGoodsList">
		<!-- 商品分类 -->

		<div class="order_state_head Between">
			<div class="order_state_list flex-center pointer">
				<div class="name display">退货退款</div>
			</div>
			<div class="search_box flex-center">
				<input type="text" placeholder="请输入" />
				<div class="searchBtn display pointer">
					<img src="./img/search.png" alt="" />
				</div>
			</div>
		</div>
		<div class="OrderList_box">
			<div class="order_list" v-for="(item, index) in goodsData" :key="index">
				<div class="head Between">
					<div class="head_time_orderCode flex-center">
						<div class="order_time">
							申请退款时间：{{ item.applyRefundTime }}
						</div>
						<div class="order_code">订单号：{{ item.refundCode }}</div>
					</div>
					<div class="head_fl pointer">
						<img src="./img/orderDel.png" alt="" />
					</div>
				</div>
				<div
					class="goods_list_box flex"
					v-for="(items, indexs) in item.orderItemVOS"
					:key="indexs"
				>
					<div
						class="goods_list"
						:class="item.orderItemVOS.length > 1 ? 'lists' : ''"
					>
						<div class="list flex-center">
							<div class="goodsImg">
								<img :src="items.goodsUrl" alt="" />
							</div>
							<div class="goods_name_specification">
								<div class="goods_name astrict">{{ items.goodsName }}</div>
								<div class="goods_specification astrict">
									{{ items.skuTitle }}
								</div>
							</div>
							<div class="goodsPrice">
								￥{{ Number(items.totalPrice).toFixed(2) }}
							</div>
							<div class="goodsNum">x{{ items.goodsNum }}</div>
						</div>
					</div>
					<div class="totalPrice flex-center column">
						<div class="price">￥{{ Number(items.unitPrice).toFixed(2) }}</div>
						<div class="freight">
							(含运费：￥{{ Number(items.freight).toFixed(2) }})
						</div>
					</div>
					<div class="orderStatus flex-center column">
						<!-- <div class="order_details" v-if="items.isRefund == 0">未退换</div>
                    <div class="order_details" v-if="items.isRefund == 1">退换</div> 
                    <div class="order_details pointer" v-if="items.isRefund == 2">撤销退换</div> -->
						<div class="order_details pointer" @click="toDetails(item.id)">
							订单详情
						</div>
						<!-- <div class="being_processed">卖家处理中</div>
                    <div class="being_processed ">请退货</div>  -->
					</div>
					<div class="operation display">
						<div
							class="cancelOrder display pointer"
							v-if="item.refundState == 1"
						>
							买家申请
						</div>
						<div
							class="cancelOrder display pointer"
							v-if="item.refundState == 2"
						>
							卖家审核
						</div>
						<div
							class="cancelOrder display pointer"
							v-if="item.refundState == 3"
						>
							买家退货
						</div>
						<div
							class="cancelOrder display pointer"
							v-if="item.refundState == 4"
						>
							退换完毕
						</div>
						<div
							class="cancelOrder display pointer"
							v-if="item.refundState == 5"
						>
							已取消退换
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		orderType: {},
	},
	components: {},
	data() {
		return {
			goodsData: [],
			params: {
				page: 1,
				limit: 20,
				token: this.$store.state.userData.token,
			},
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		//获取退款订单列表
		getData() {
			this.api.findPageUserRefundOrder(this.params).then((res) => {
				this.goodsData = res.data
			})
		},
		toDetails(id) {
			this.$router.push({
				path: '/userInfo/returnGoodsDetails',
				query: {
					orderId: id,
					type: '退款',
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.returnGoodsList {
	width: 1200px;
	margin: 0 auto;
	margin-top: 27px;
	margin-bottom: 27px;
	background: white;
	.order_state_head {
		width: 100%;
		height: 62px;
		padding: 0px 30px;
		box-sizing: border-box;
		border-bottom: 1px solid #eaeaea;
		.name {
			height: 62px;
			font-size: 16px;
			color: #333;
			position: relative;
			margin-right: 30px;
			.lines {
				width: 100%;
				height: 2px;
				background: #e62129;
				position: absolute;
				left: 0px;
				bottom: 0px;
			}
		}
		.orderActive {
			color: #e62129 !important;
		}
		.search_box {
			width: 300px;
			height: 36px;
			background: #ffffff;
			border: 1px solid #eaeaea;
			border-radius: 3px;
			input {
				width: 244px;
				height: 34px;
				padding-left: 20px;
				box-sizing: border-box;
			}
			.searchBtn {
				width: 56px;
				height: 36px;
				background: #d0d0d0;
				border-radius: 0px 3px 3px 0px;
				img {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
	.OrderList_box {
		padding: 20px 30px;
		.order_list {
			border: 1px solid #eaeaea;
			margin-bottom: 20px;
			.head {
				width: 100%;
				height: 27px;
				background: #f4f5f5;
				padding-left: 23px;
				padding-right: 18px;
				box-sizing: border-box;
				.head_time_orderCode {
					font-size: 12px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 30px;
					.order_time {
					}
					.order_code {
						color: #666666;
						margin-left: 20px;
					}
				}
				.head_fl {
					img {
						width: 17px;
						height: 17px;
					}
				}
			}
			.goods_list_box {
				.goods_list {
					width: 525px;
					border-right: 1px solid #eaeaea;
					.lists:nth-child(1) {
						border-top: none !important;
					}
					.lists {
						padding: 22px 21px 20px 20px !important;
						border-top: 1px solid #eaeaea;
					}
					.list {
						padding: 22px 21px 25px 20px;
						box-sizing: border-box;
						.goodsImg {
							width: 78px;
							img {
								width: 78px;
								height: 78px;
							}
						}
						.goods_name_specification {
							width: 231px;
							margin-left: 17px;
							.goods_name {
								width: 231px;
								height: 31px;
								font-size: 12px;
								color: #333333;
							}
							.goods_specification {
								width: 231px;
								height: 31px;
								font-size: 12px;
								color: #888888;
								margin-top: 13px;
							}
						}
						.goodsPrice {
							font-size: 14px;
							color: #666666;
							margin-left: 50px;
						}
						.goodsNum {
							font-size: 14px;
							color: #666666;
							margin-left: 41px;
						}
					}
				}
				.totalPrice {
					min-width: 160px;
					font-size: 14px;
					border-right: 1px solid #eaeaea;
					.price {
						color: #ec454c;
						font-family: Microsoft YaHei;
						font-weight: bold;
						margin-top: 22px;
					}
					.freight {
						color: #666666;
						margin-top: 5px;
					}
				}
				.orderStatus {
					min-width: 135px;
					font-size: 12px;
					color: #666666;
					line-height: 22px;
					padding-top: 22px;
					box-sizing: border-box;
					border-right: 1px solid #eaeaea;
					.being_processed {
						color: #ec454c;
					}
				}
				.operation {
					width: 100%;

					.cancelOrder {
						font-size: 16px;
						color: #333333;
					}
				}
			}
		}
	}
}
</style>